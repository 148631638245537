/* Reset default styles */
/* import raleway font */
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');



* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Global styles */
body {
    font-family: 'Raleway', sans-serif;
    background-color: #f2f2f2;
    color: #333;
}

label{
    font-weight: 700;

}

::placeholder {
    color: #333;
}

select {
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
}





/* Add your custom styles below */