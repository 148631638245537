/* Alternative Taxi-Themed Background with Stripes */
.taxi-striped-bg {
    background-color: #FFCC00; /* Taxi Yellow */
    background-image: repeating-linear-gradient(
        to right,
        #000000 0%,
        #000000 10%,
        #FFCC00 10%,
        #FFCC00 50%
    );
    background-size: 50px 50px; /* Adjust width of stripes */
}
