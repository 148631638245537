/* TaxiFareCalculator.css */

/* Background color and pattern */
.map-container {
    background: linear-gradient(to right, #FFD700, #F0F0F0); /* Taxi yellow gradient */
    padding: 2rem;
}

/* Form and container styling */
.calculation {
    background-color: #FFF; /* White background for form container */
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Styling for heading */
.calculation h4,
.calculation h3 {
    background-color: #FFD700; /* Yellow background for headings */
    padding: 0.5rem;
    border-radius: 5px;
}

/* Button styling */
.btn-success {
    background-color: #28a745; /* Bootstrap success color */
    border-color: #28a745;
}

.btn-primary {
    background-color: #007bff; /* Bootstrap primary color */
    border-color: #007bff;
}

.btn-secondary {
    background-color: #6c757d; /* Bootstrap secondary color */
    border-color: #6c757d;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .calculation {
        padding: 1rem;
    }
}

.text-center {
    text-align: center;
}

.fare-calculation {
    margin-top: 1rem;
}

.disclaimer {
    margin-top: 1rem;
}

/* Input and form styling */
.form-control {
    margin-bottom: 1rem;
}

.form-select {
    margin-bottom: 1rem;
}

input[type="number"] {
    -moz-appearance: textfield; /* Hide number input spinners in Firefox */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
